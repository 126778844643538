import request from "@/utils/request";

export function exportExcel(event_name) {
  return request({
    url: "/api/v1/admin/excel",
    method: "get",
    params: {
      event_name,
    },
  });
}
// 删除所有表
export function deleteFiles() {
  return request({
    url: "/api/v1/admin/excel",
    method: "delete",
  });
}
// 发布隐私政策
export function pushPrivatePolice(rule) {
  return request({
    url: "/api/v1/admin/Privacy_Policy",
    method: "post",
    data: rule,
  });
}

// 统计
export function getStatic() {
  return request({
    url: "/api/v1/admin/statistics",
    method: "get",
  });
}

//发送请求上传图片
export function uploadImg(formData) {
  return request({
    url: "/api/v1/admin/updata_img",
    method: "POST",
    data: formData,
    headers: {
        'Content-Type': 'multipart/form-data'
      }
  });
}

// 获取白名单列表
export function getFriendList(query){
    return request({
        url:"/api/v1/admin/Friend",
        method:'get',
        params:query
    })
}

// 存储
export function saveFriend(query){
    return request({
        url:"/api/v1/admin/Friend",
        method:'post',
        params:query
    })
}

// 删除
export function deleteFriend(friend_id){
    return request({
        url:"/api/v1/admin/Friend",
        method:'delete',
        params:{
            friend_id
        }
    })
}

// 更新
export function putFriend(params){
  return request({
    url:"/api/v1/admin/Friend",
    method:'put',
    params:params
})
}
// 发送公告
export function sendMsg(form){
  return request({
    url:"/api/v1/admin/post_qqbotmsg",
    method:"POST",
    params:{
      qqgrope:form.qqgrope,
      is_atall:form.is_atall
    },
    data:form.text,
    headers:{
      "Content-Type":"application/json"
    }
  })
}
